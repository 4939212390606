<template>
  <allocationForm :data="allocation" :loading="loading" />
</template>
<script>
import allocationForm from './components/allocationsForm.vue'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
import staticData from './static_data'
const AllocationsRepository = RepositoryFactory.get('GradingModule', 'allocations')
export default {
  name:'edit',
  components:{allocationForm},
  data () {
    return {
      allocation:{},
      loading:true
    }
  },
  methods:{
    getAllocation () {
      this.loading = true
      AllocationsRepository.getAllocation(this.$route.params.id)
        .then((res) => {
          this.allocation = res.data
        })
        .catch(this.errMsg)
        .finally(() => { this.loading = false })
      // TODO : Remove it after fix the integration
      // this.allocation = staticData.show.data
    }
  },
  created () {
    // this.getAllocation() // it is called at the form component
  }
}
</script>
